







































import Vue from "vue";
import { mapState } from "vuex";
import annyang from "annyang";
import { RecordRTCPromisesHandler, StereoAudioRecorder } from "recordrtc";
import { dispatch } from "@/store";

interface Message {
  type: string;
  author: string;
  data: { text: string };
}

export default Vue.extend({
  data() {
    return {
      token: localStorage.getItem("ai-token") || "",
      phone: "",
      recorder: {} as any,
      isRecording: false,
      participants: [{ id: "am", name: "AM Master" }],
      messageList: [] as Message[],
      newMessagesCount: 0,
      isChatOpen: false,
      loading: false,
      text: "",
      timer: 0,
      colors: {
        header: { bg: "#4e8cff", text: "#ffffff" },
        launcher: { bg: "#4e8cff" },
        messageList: { bg: "#ffffff" },
        sentMessage: { bg: "#4e8cff", text: "#ffffff" },
        receivedMessage: { bg: "#eaeaea", text: "#222222" },
        userInput: { bg: "#f4f7f9", text: "#565867" }
      }
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapState("app", {
      isMobile: "isMobile",
      isCN: "isCN"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    })
  },
  methods: {
    initInput() {
      setTimeout(() => {
        const input = document.querySelector(
          ".sc-user-input--text"
        ) as HTMLInputElement;
        if (input) {
          input.onpaste = event => {
            event.preventDefault();
            const text = (
              event.clipboardData || (window as any).clipboardData
            ).getData("text/plain");
            document.execCommand("insertHTML", false, text);
          };
          input.addEventListener("input", () => {
            if (input.innerText.length > 2000) {
              const text = input.innerText || "";
              input.innerText = "";
              document.execCommand("insertHTML", false, text.slice(0, 2000));
            }
          });
        }
      }, 1000);
    },
    onCall() {
      console.log("onCall");
      if (this.isRecording) return;
      setTimeout(() => {
        this.messageList = [
          ...this.messageList,
          {
            type: "text",
            author: "assistant",
            data: { text: `Hi! 请在6秒内说出你的问题` }
          }
        ];
      }, 100);
      setTimeout(this.onRecord, 100);
      setTimeout(this.onRecord, 6000);
    },
    onRecord() {
      console.log("onRecord", "isRecording", this.isRecording);
      if (this.isRecording) {
        this.recorder.stopRecording().then(() => {
          this.recorder.getBlob().then((blob: Blob) => {
            dispatch
              .aiAsr(blob)
              .then(res => {
                this.isRecording = false;
                this.loading = false;
                this.onMessageWasSent({
                  author: "me",
                  type: "text",
                  data: { text: res.result }
                });
              })
              .catch(() => {
                this.loading = false;
              });
          });
        });
      } else {
        // 开始录制
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          // 创建Recorder对象
          this.recorder = new RecordRTCPromisesHandler(stream, {
            type: "audio",
            mimeType: "audio/wav",
            recorderType: StereoAudioRecorder,
            sampleRate: 96000,
            desiredSampRate: 16000,
            bufferSize: 16384,
            numberOfAudioChannels: 2
          });
          this.recorder.startRecording();
          this.isRecording = true;
          this.loading = true;
        });
      }
    },
    onMessageWasSent(message: Message) {
      this.messageList = [...this.messageList, message];
      this.loading = true;
      if (message.data.text.startsWith("/login")) {
        this.sendCode(message.data.text.replace("/login", "").trim());
      } else if (this.phone && /\d{6}/.test(message.data.text.trim())) {
        this.login(message.data.text.trim());
      } else if (message.data.text.startsWith("/logout")) {
        this.logout();
      } else if (message.data.text.startsWith("/add")) {
        this.addPrompt(message.data.text.replace("/add", "").trim());
      } else {
        this.chat(message.data.text);
      }
    },
    sendCode(phone: string) {
      const mobileRe = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (!mobileRe.test(phone)) {
        this.loading = false;
        this.messageList = [
          ...this.messageList,
          {
            type: "text",
            author: "assistant",
            data: { text: "请输入正确的手机号码" }
          }
        ];
        return;
      }
      const index = this.messageList.length;
      this.messageList[index] = {
        type: "text",
        author: "assistant",
        data: { text: "" }
      };
      dispatch
        .aiSignInPhoneCode(phone)
        .then(() => {
          this.phone = phone;
          this.loading = false;
          this.messageList[index] = {
            type: "text",
            author: "assistant",
            data: { text: "短信验证码已发送，请回复收到的验证码" }
          };
        })
        .catch(res => {
          this.loading = false;
          this.messageList[index] = {
            type: "text",
            author: "assistant",
            data: {
              text:
                "" +
                this.$t(
                  "error." + (res.detail || res.text[0]).replace(/\.|'/g, "")
                )
            }
          };
        });
    },
    login(code: string) {
      const index = this.messageList.length;
      this.messageList[index] = {
        type: "text",
        author: "assistant",
        data: { text: "" }
      };
      dispatch
        .aiSignInPhone({ phone: this.phone, code })
        .then(res => {
          this.phone = "";
          this.token = res.token;
          localStorage.setItem("ai-token", this.token);
          this.loading = false;
          this.messageList[index] = {
            type: "text",
            author: "assistant",
            data: { text: "登录成功" }
          };
        })
        .catch(res => {
          this.loading = false;
          this.messageList[index] = {
            type: "text",
            author: "assistant",
            data: {
              text:
                "" +
                this.$t(
                  "error." + (res.detail || res.text[0]).replace(/\.|'/g, "")
                )
            }
          };
        });
    },
    logout() {
      const index = this.messageList.length;
      this.phone = "";
      this.token = "";
      localStorage.setItem("ai-token", this.token);
      this.loading = false;
      this.messageList[index] = {
        type: "text",
        author: "assistant",
        data: { text: "退出成功" }
      };
    },
    addPrompt(text: string) {
      if (!this.token) {
        this.loading = false;
        this.messageList = [
          ...this.messageList,
          {
            type: "text",
            author: "assistant",
            data: { text: "请先使用手机号进行登录" }
          }
        ];
        return;
      }
      const index = this.messageList.length;
      this.messageList[index] = {
        type: "text",
        author: "assistant",
        data: { text: "" }
      };
      dispatch
        .aiAddPrompt({ text, token: this.token })
        .then(() => {
          this.loading = false;
          this.messageList[index] = {
            type: "text",
            author: "assistant",
            data: { text: "添加成功" }
          };
        })
        .catch(res => {
          this.loading = false;
          this.messageList[index] = {
            type: "text",
            author: "assistant",
            data: {
              text:
                "" +
                this.$t(
                  "error." + (res.detail || res.text[0]).replace(/\.|'/g, "")
                )
            }
          };
        });
    },
    chat(message: string) {
      const index = this.messageList.length;
      this.messageList[index] = {
        type: "text",
        author: "assistant",
        data: { text: "" }
      };
      dispatch
        .aiChat({ message })
        .then(response => {
          const reader = response.body.getReader();
          const read = () => {
            return reader
              .read()
              .then(
                ({ done, value }: { done: boolean; value: BufferSource }) => {
                  this.text = this.text + new TextDecoder().decode(value);
                  this.loading = true;
                  this.InputText();
                  if (done) return;
                  return read();
                }
              );
          };
          read();
        })
        .catch(() => {
          this.loading = false;
          this.messageList[index] = {
            type: "text",
            author: "assistant",
            data: { text: `服务异常，请稍候再进行尝试` }
          };
        });
    },
    InputText() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (this.text.length) {
          const firstChar = this.text.slice(0, 1);
          this.text = this.text.slice(1);
          const index = this.messageList.length - 1;
          this.messageList[index] = {
            type: "text",
            author: "assistant",
            data: {
              text: (this.messageList[index]?.data.text || "") + firstChar
            }
          };
          this.messageList = [...this.messageList];
          if (this.text.length) {
            this.InputText();
          } else {
            this.loading = false;
          }
        }
      }, Math.floor((Math.random() * 2000) / (this.text.length + 5)));
    }
  },
  mounted() {
    const commands = {
      "hi master": this.onCall,
      "hai master": this.onCall,
      "he master": this.onCall,
      "hei master": this.onCall
    };
    annyang.addCommands(commands);
    annyang.start();
    this.initInput();
  }
});
